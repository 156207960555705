/* !!! DO NOT EDIT !!! */ /* This file has been generated by the token pipeline */ import { black, blackAlpha, grey, grey10Alpha, grey12Alpha, grey14Alpha, white, whiteAlpha } from '../global/colors';
export const generateColorTokens = (brand)=>({
        colorNeutralForeground1: white,
        colorNeutralForeground1Hover: white,
        colorNeutralForeground1Pressed: white,
        colorNeutralForeground1Selected: white,
        colorNeutralForeground2: grey[84],
        colorNeutralForeground2Hover: white,
        colorNeutralForeground2Pressed: white,
        colorNeutralForeground2Selected: white,
        colorNeutralForeground2BrandHover: brand[100],
        colorNeutralForeground2BrandPressed: brand[90],
        colorNeutralForeground2BrandSelected: brand[100],
        colorNeutralForeground3: grey[68],
        colorNeutralForeground3Hover: grey[84],
        colorNeutralForeground3Pressed: grey[84],
        colorNeutralForeground3Selected: grey[84],
        colorNeutralForeground3BrandHover: brand[100],
        colorNeutralForeground3BrandPressed: brand[90],
        colorNeutralForeground3BrandSelected: brand[100],
        colorNeutralForeground4: grey[60],
        colorNeutralForegroundDisabled: grey[36],
        colorNeutralForegroundInvertedDisabled: whiteAlpha[40],
        colorBrandForegroundLink: brand[100],
        colorBrandForegroundLinkHover: brand[110],
        colorBrandForegroundLinkPressed: brand[90],
        colorBrandForegroundLinkSelected: brand[100],
        colorNeutralForeground2Link: grey[84],
        colorNeutralForeground2LinkHover: white,
        colorNeutralForeground2LinkPressed: white,
        colorNeutralForeground2LinkSelected: white,
        colorCompoundBrandForeground1: brand[100],
        colorCompoundBrandForeground1Hover: brand[110],
        colorCompoundBrandForeground1Pressed: brand[90],
        colorBrandForeground1: brand[100],
        colorBrandForeground2: brand[110],
        colorBrandForeground2Hover: brand[130],
        colorBrandForeground2Pressed: brand[160],
        colorNeutralForeground1Static: grey[14],
        colorNeutralForegroundStaticInverted: white,
        colorNeutralForegroundInverted: grey[14],
        colorNeutralForegroundInvertedHover: grey[14],
        colorNeutralForegroundInvertedPressed: grey[14],
        colorNeutralForegroundInvertedSelected: grey[14],
        colorNeutralForegroundInverted2: grey[14],
        colorNeutralForegroundOnBrand: white,
        colorNeutralForegroundInvertedLink: white,
        colorNeutralForegroundInvertedLinkHover: white,
        colorNeutralForegroundInvertedLinkPressed: white,
        colorNeutralForegroundInvertedLinkSelected: white,
        colorBrandForegroundInverted: brand[80],
        colorBrandForegroundInvertedHover: brand[70],
        colorBrandForegroundInvertedPressed: brand[60],
        colorBrandForegroundOnLight: brand[80],
        colorBrandForegroundOnLightHover: brand[70],
        colorBrandForegroundOnLightPressed: brand[50],
        colorBrandForegroundOnLightSelected: brand[60],
        colorNeutralBackground1: grey[16],
        colorNeutralBackground1Hover: grey[24],
        colorNeutralBackground1Pressed: grey[12],
        colorNeutralBackground1Selected: grey[22],
        colorNeutralBackground2: grey[12],
        colorNeutralBackground2Hover: grey[20],
        colorNeutralBackground2Pressed: grey[8],
        colorNeutralBackground2Selected: grey[18],
        colorNeutralBackground3: grey[8],
        colorNeutralBackground3Hover: grey[16],
        colorNeutralBackground3Pressed: grey[4],
        colorNeutralBackground3Selected: grey[14],
        colorNeutralBackground4: grey[4],
        colorNeutralBackground4Hover: grey[12],
        colorNeutralBackground4Pressed: black,
        colorNeutralBackground4Selected: grey[10],
        colorNeutralBackground5: black,
        colorNeutralBackground5Hover: grey[8],
        colorNeutralBackground5Pressed: grey[2],
        colorNeutralBackground5Selected: grey[6],
        colorNeutralBackground6: grey[20],
        colorNeutralBackgroundInverted: white,
        colorNeutralBackgroundStatic: grey[24],
        colorNeutralBackgroundAlpha: grey10Alpha[50],
        colorNeutralBackgroundAlpha2: grey12Alpha[70],
        colorSubtleBackground: 'transparent',
        colorSubtleBackgroundHover: grey[22],
        colorSubtleBackgroundPressed: grey[18],
        colorSubtleBackgroundSelected: grey[20],
        colorSubtleBackgroundLightAlphaHover: grey14Alpha[80],
        colorSubtleBackgroundLightAlphaPressed: grey14Alpha[50],
        colorSubtleBackgroundLightAlphaSelected: 'transparent',
        colorSubtleBackgroundInverted: 'transparent',
        colorSubtleBackgroundInvertedHover: blackAlpha[10],
        colorSubtleBackgroundInvertedPressed: blackAlpha[30],
        colorSubtleBackgroundInvertedSelected: blackAlpha[20],
        colorTransparentBackground: 'transparent',
        colorTransparentBackgroundHover: 'transparent',
        colorTransparentBackgroundPressed: 'transparent',
        colorTransparentBackgroundSelected: 'transparent',
        colorNeutralBackgroundDisabled: grey[8],
        colorNeutralBackgroundInvertedDisabled: whiteAlpha[10],
        colorNeutralStencil1: grey[34],
        colorNeutralStencil2: grey[20],
        colorNeutralStencil1Alpha: whiteAlpha[10],
        colorNeutralStencil2Alpha: whiteAlpha[5],
        colorBackgroundOverlay: blackAlpha[50],
        colorScrollbarOverlay: whiteAlpha[60],
        colorBrandBackground: brand[70],
        colorBrandBackgroundHover: brand[80],
        colorBrandBackgroundPressed: brand[40],
        colorBrandBackgroundSelected: brand[60],
        colorCompoundBrandBackground: brand[100],
        colorCompoundBrandBackgroundHover: brand[110],
        colorCompoundBrandBackgroundPressed: brand[90],
        colorBrandBackgroundStatic: brand[80],
        colorBrandBackground2: brand[20],
        colorBrandBackground2Hover: brand[40],
        colorBrandBackground2Pressed: brand[10],
        colorBrandBackgroundInverted: white,
        colorBrandBackgroundInvertedHover: brand[160],
        colorBrandBackgroundInvertedPressed: brand[140],
        colorBrandBackgroundInvertedSelected: brand[150],
        colorNeutralStrokeAccessible: grey[68],
        colorNeutralStrokeAccessibleHover: grey[74],
        colorNeutralStrokeAccessiblePressed: grey[70],
        colorNeutralStrokeAccessibleSelected: brand[100],
        colorNeutralStroke1: grey[40],
        colorNeutralStroke1Hover: grey[46],
        colorNeutralStroke1Pressed: grey[42],
        colorNeutralStroke1Selected: grey[44],
        colorNeutralStroke2: grey[32],
        colorNeutralStroke3: grey[24],
        colorNeutralStrokeSubtle: grey[4],
        colorNeutralStrokeOnBrand: grey[16],
        colorNeutralStrokeOnBrand2: white,
        colorNeutralStrokeOnBrand2Hover: white,
        colorNeutralStrokeOnBrand2Pressed: white,
        colorNeutralStrokeOnBrand2Selected: white,
        colorBrandStroke1: brand[100],
        colorBrandStroke2: brand[50],
        colorBrandStroke2Hover: brand[50],
        colorBrandStroke2Pressed: brand[30],
        colorBrandStroke2Contrast: brand[50],
        colorCompoundBrandStroke: brand[100],
        colorCompoundBrandStrokeHover: brand[110],
        colorCompoundBrandStrokePressed: brand[90],
        colorNeutralStrokeDisabled: grey[26],
        colorNeutralStrokeInvertedDisabled: whiteAlpha[40],
        colorTransparentStroke: 'transparent',
        colorTransparentStrokeInteractive: 'transparent',
        colorTransparentStrokeDisabled: 'transparent',
        colorNeutralStrokeAlpha: whiteAlpha[10],
        colorNeutralStrokeAlpha2: whiteAlpha[20],
        colorStrokeFocus1: black,
        colorStrokeFocus2: white,
        colorNeutralShadowAmbient: 'rgba(0,0,0,0.24)',
        colorNeutralShadowKey: 'rgba(0,0,0,0.28)',
        colorNeutralShadowAmbientLighter: 'rgba(0,0,0,0.12)',
        colorNeutralShadowKeyLighter: 'rgba(0,0,0,0.14)',
        colorNeutralShadowAmbientDarker: 'rgba(0,0,0,0.40)',
        colorNeutralShadowKeyDarker: 'rgba(0,0,0,0.48)',
        colorBrandShadowAmbient: 'rgba(0,0,0,0.30)',
        colorBrandShadowKey: 'rgba(0,0,0,0.25)'
    });
