import { useIsomorphicLayoutEffect } from '@fluentui/react-utilities';
import * as React from 'react';
/**
 * @internal
 * This hook returns context selected value by selector.
 * It will only accept context created by `createContext`.
 * It will trigger re-render if only the selected value is referentially changed.
 */ export const useContextSelector = (context, selector)=>{
    const contextValue = React.useContext(context);
    const { value: { current: value }, version: { current: version }, listeners } = contextValue;
    const selected = selector(value);
    const [state, dispatch] = React.useReducer((prevState, payload)=>{
        if (!payload) {
            // early bail out when is dispatched during render
            return [
                value,
                selected
            ];
        }
        if (payload[0] <= version) {
            if (objectIs(prevState[1], selected)) {
                return prevState; // bail out
            }
            return [
                value,
                selected
            ];
        }
        try {
            if (objectIs(prevState[0], payload[1])) {
                return prevState; // do not update
            }
            const nextSelected = selector(payload[1]);
            if (objectIs(prevState[1], nextSelected)) {
                return prevState; // do not update
            }
            return [
                payload[1],
                nextSelected
            ];
        } catch (e) {
        // ignored (stale props or some other reason)
        }
        // explicitly spread to enforce typing
        return [
            prevState[0],
            prevState[1]
        ]; // schedule update
    }, [
        value,
        selected
    ]);
    if (!objectIs(state[1], selected)) {
        // schedule re-render
        // this is safe because it's self contained
        dispatch(undefined);
    }
    useIsomorphicLayoutEffect(()=>{
        listeners.push(dispatch);
        return ()=>{
            const index = listeners.indexOf(dispatch);
            listeners.splice(index, 1);
        };
    }, [
        listeners
    ]);
    return state[1];
};
/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */ // eslint-disable-next-line @typescript-eslint/no-explicit-any
function is(x, y) {
    return x === y && (x !== 0 || 1 / x === 1 / y) || x !== x && y !== y // eslint-disable-line no-self-compare
    ;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const objectIs = // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore fallback to native if it exists (not in IE11)
typeof Object.is === 'function' ? Object.is : is;
