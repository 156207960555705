export const tokens = {
    // Color tokens
    colorNeutralForeground1: 'var(--colorNeutralForeground1)',
    colorNeutralForeground1Hover: 'var(--colorNeutralForeground1Hover)',
    colorNeutralForeground1Pressed: 'var(--colorNeutralForeground1Pressed)',
    colorNeutralForeground1Selected: 'var(--colorNeutralForeground1Selected)',
    colorNeutralForeground2: 'var(--colorNeutralForeground2)',
    colorNeutralForeground2Hover: 'var(--colorNeutralForeground2Hover)',
    colorNeutralForeground2Pressed: 'var(--colorNeutralForeground2Pressed)',
    colorNeutralForeground2Selected: 'var(--colorNeutralForeground2Selected)',
    colorNeutralForeground2BrandHover: 'var(--colorNeutralForeground2BrandHover)',
    colorNeutralForeground2BrandPressed: 'var(--colorNeutralForeground2BrandPressed)',
    colorNeutralForeground2BrandSelected: 'var(--colorNeutralForeground2BrandSelected)',
    colorNeutralForeground3: 'var(--colorNeutralForeground3)',
    colorNeutralForeground3Hover: 'var(--colorNeutralForeground3Hover)',
    colorNeutralForeground3Pressed: 'var(--colorNeutralForeground3Pressed)',
    colorNeutralForeground3Selected: 'var(--colorNeutralForeground3Selected)',
    colorNeutralForeground3BrandHover: 'var(--colorNeutralForeground3BrandHover)',
    colorNeutralForeground3BrandPressed: 'var(--colorNeutralForeground3BrandPressed)',
    colorNeutralForeground3BrandSelected: 'var(--colorNeutralForeground3BrandSelected)',
    colorNeutralForeground4: 'var(--colorNeutralForeground4)',
    colorNeutralForegroundDisabled: 'var(--colorNeutralForegroundDisabled)',
    colorBrandForegroundLink: 'var(--colorBrandForegroundLink)',
    colorBrandForegroundLinkHover: 'var(--colorBrandForegroundLinkHover)',
    colorBrandForegroundLinkPressed: 'var(--colorBrandForegroundLinkPressed)',
    colorBrandForegroundLinkSelected: 'var(--colorBrandForegroundLinkSelected)',
    colorNeutralForeground2Link: 'var(--colorNeutralForeground2Link)',
    colorNeutralForeground2LinkHover: 'var(--colorNeutralForeground2LinkHover)',
    colorNeutralForeground2LinkPressed: 'var(--colorNeutralForeground2LinkPressed)',
    colorNeutralForeground2LinkSelected: 'var(--colorNeutralForeground2LinkSelected)',
    colorCompoundBrandForeground1: 'var(--colorCompoundBrandForeground1)',
    colorCompoundBrandForeground1Hover: 'var(--colorCompoundBrandForeground1Hover)',
    colorCompoundBrandForeground1Pressed: 'var(--colorCompoundBrandForeground1Pressed)',
    colorNeutralForegroundOnBrand: 'var(--colorNeutralForegroundOnBrand)',
    colorNeutralForegroundInverted: 'var(--colorNeutralForegroundInverted)',
    colorNeutralForegroundInvertedHover: 'var(--colorNeutralForegroundInvertedHover)',
    colorNeutralForegroundInvertedPressed: 'var(--colorNeutralForegroundInvertedPressed)',
    colorNeutralForegroundInvertedSelected: 'var(--colorNeutralForegroundInvertedSelected)',
    colorNeutralForegroundInverted2: 'var(--colorNeutralForegroundInverted2)',
    colorNeutralForegroundStaticInverted: 'var(--colorNeutralForegroundStaticInverted)',
    colorNeutralForegroundInvertedLink: 'var(--colorNeutralForegroundInvertedLink)',
    colorNeutralForegroundInvertedLinkHover: 'var(--colorNeutralForegroundInvertedLinkHover)',
    colorNeutralForegroundInvertedLinkPressed: 'var(--colorNeutralForegroundInvertedLinkPressed)',
    colorNeutralForegroundInvertedLinkSelected: 'var(--colorNeutralForegroundInvertedLinkSelected)',
    colorNeutralForegroundInvertedDisabled: 'var(--colorNeutralForegroundInvertedDisabled)',
    colorBrandForeground1: 'var(--colorBrandForeground1)',
    colorBrandForeground2: 'var(--colorBrandForeground2)',
    colorBrandForeground2Hover: 'var(--colorBrandForeground2Hover)',
    colorBrandForeground2Pressed: 'var(--colorBrandForeground2Pressed)',
    colorNeutralForeground1Static: 'var(--colorNeutralForeground1Static)',
    colorBrandForegroundInverted: 'var(--colorBrandForegroundInverted)',
    colorBrandForegroundInvertedHover: 'var(--colorBrandForegroundInvertedHover)',
    colorBrandForegroundInvertedPressed: 'var(--colorBrandForegroundInvertedPressed)',
    colorBrandForegroundOnLight: 'var(--colorBrandForegroundOnLight)',
    colorBrandForegroundOnLightHover: 'var(--colorBrandForegroundOnLightHover)',
    colorBrandForegroundOnLightPressed: 'var(--colorBrandForegroundOnLightPressed)',
    colorBrandForegroundOnLightSelected: 'var(--colorBrandForegroundOnLightSelected)',
    colorNeutralBackground1: 'var(--colorNeutralBackground1)',
    colorNeutralBackground1Hover: 'var(--colorNeutralBackground1Hover)',
    colorNeutralBackground1Pressed: 'var(--colorNeutralBackground1Pressed)',
    colorNeutralBackground1Selected: 'var(--colorNeutralBackground1Selected)',
    colorNeutralBackground2: 'var(--colorNeutralBackground2)',
    colorNeutralBackground2Hover: 'var(--colorNeutralBackground2Hover)',
    colorNeutralBackground2Pressed: 'var(--colorNeutralBackground2Pressed)',
    colorNeutralBackground2Selected: 'var(--colorNeutralBackground2Selected)',
    colorNeutralBackground3: 'var(--colorNeutralBackground3)',
    colorNeutralBackground3Hover: 'var(--colorNeutralBackground3Hover)',
    colorNeutralBackground3Pressed: 'var(--colorNeutralBackground3Pressed)',
    colorNeutralBackground3Selected: 'var(--colorNeutralBackground3Selected)',
    colorNeutralBackground4: 'var(--colorNeutralBackground4)',
    colorNeutralBackground4Hover: 'var(--colorNeutralBackground4Hover)',
    colorNeutralBackground4Pressed: 'var(--colorNeutralBackground4Pressed)',
    colorNeutralBackground4Selected: 'var(--colorNeutralBackground4Selected)',
    colorNeutralBackground5: 'var(--colorNeutralBackground5)',
    colorNeutralBackground5Hover: 'var(--colorNeutralBackground5Hover)',
    colorNeutralBackground5Pressed: 'var(--colorNeutralBackground5Pressed)',
    colorNeutralBackground5Selected: 'var(--colorNeutralBackground5Selected)',
    colorNeutralBackground6: 'var(--colorNeutralBackground6)',
    colorNeutralBackgroundInverted: 'var(--colorNeutralBackgroundInverted)',
    colorNeutralBackgroundStatic: 'var(--colorNeutralBackgroundStatic)',
    colorNeutralBackgroundAlpha: 'var(--colorNeutralBackgroundAlpha)',
    colorNeutralBackgroundAlpha2: 'var(--colorNeutralBackgroundAlpha2)',
    colorSubtleBackground: 'var(--colorSubtleBackground)',
    colorSubtleBackgroundHover: 'var(--colorSubtleBackgroundHover)',
    colorSubtleBackgroundPressed: 'var(--colorSubtleBackgroundPressed)',
    colorSubtleBackgroundSelected: 'var(--colorSubtleBackgroundSelected)',
    colorSubtleBackgroundLightAlphaHover: 'var(--colorSubtleBackgroundLightAlphaHover)',
    colorSubtleBackgroundLightAlphaPressed: 'var(--colorSubtleBackgroundLightAlphaPressed)',
    colorSubtleBackgroundLightAlphaSelected: 'var(--colorSubtleBackgroundLightAlphaSelected)',
    colorSubtleBackgroundInverted: 'var(--colorSubtleBackgroundInverted)',
    colorSubtleBackgroundInvertedHover: 'var(--colorSubtleBackgroundInvertedHover)',
    colorSubtleBackgroundInvertedPressed: 'var(--colorSubtleBackgroundInvertedPressed)',
    colorSubtleBackgroundInvertedSelected: 'var(--colorSubtleBackgroundInvertedSelected)',
    colorTransparentBackground: 'var(--colorTransparentBackground)',
    colorTransparentBackgroundHover: 'var(--colorTransparentBackgroundHover)',
    colorTransparentBackgroundPressed: 'var(--colorTransparentBackgroundPressed)',
    colorTransparentBackgroundSelected: 'var(--colorTransparentBackgroundSelected)',
    colorNeutralBackgroundDisabled: 'var(--colorNeutralBackgroundDisabled)',
    colorNeutralBackgroundInvertedDisabled: 'var(--colorNeutralBackgroundInvertedDisabled)',
    colorNeutralStencil1: 'var(--colorNeutralStencil1)',
    colorNeutralStencil2: 'var(--colorNeutralStencil2)',
    colorNeutralStencil1Alpha: 'var(--colorNeutralStencil1Alpha)',
    colorNeutralStencil2Alpha: 'var(--colorNeutralStencil2Alpha)',
    colorBackgroundOverlay: 'var(--colorBackgroundOverlay)',
    colorScrollbarOverlay: 'var(--colorScrollbarOverlay)',
    colorBrandBackground: 'var(--colorBrandBackground)',
    colorBrandBackgroundHover: 'var(--colorBrandBackgroundHover)',
    colorBrandBackgroundPressed: 'var(--colorBrandBackgroundPressed)',
    colorBrandBackgroundSelected: 'var(--colorBrandBackgroundSelected)',
    colorCompoundBrandBackground: 'var(--colorCompoundBrandBackground)',
    colorCompoundBrandBackgroundHover: 'var(--colorCompoundBrandBackgroundHover)',
    colorCompoundBrandBackgroundPressed: 'var(--colorCompoundBrandBackgroundPressed)',
    colorBrandBackgroundStatic: 'var(--colorBrandBackgroundStatic)',
    colorBrandBackground2: 'var(--colorBrandBackground2)',
    colorBrandBackground2Hover: 'var(--colorBrandBackground2Hover)',
    colorBrandBackground2Pressed: 'var(--colorBrandBackground2Pressed)',
    colorBrandBackgroundInverted: 'var(--colorBrandBackgroundInverted)',
    colorBrandBackgroundInvertedHover: 'var(--colorBrandBackgroundInvertedHover)',
    colorBrandBackgroundInvertedPressed: 'var(--colorBrandBackgroundInvertedPressed)',
    colorBrandBackgroundInvertedSelected: 'var(--colorBrandBackgroundInvertedSelected)',
    colorNeutralStrokeAccessible: 'var(--colorNeutralStrokeAccessible)',
    colorNeutralStrokeAccessibleHover: 'var(--colorNeutralStrokeAccessibleHover)',
    colorNeutralStrokeAccessiblePressed: 'var(--colorNeutralStrokeAccessiblePressed)',
    colorNeutralStrokeAccessibleSelected: 'var(--colorNeutralStrokeAccessibleSelected)',
    colorNeutralStroke1: 'var(--colorNeutralStroke1)',
    colorNeutralStroke1Hover: 'var(--colorNeutralStroke1Hover)',
    colorNeutralStroke1Pressed: 'var(--colorNeutralStroke1Pressed)',
    colorNeutralStroke1Selected: 'var(--colorNeutralStroke1Selected)',
    colorNeutralStroke2: 'var(--colorNeutralStroke2)',
    colorNeutralStroke3: 'var(--colorNeutralStroke3)',
    colorNeutralStrokeSubtle: 'var(--colorNeutralStrokeSubtle)',
    colorNeutralStrokeOnBrand: 'var(--colorNeutralStrokeOnBrand)',
    colorNeutralStrokeOnBrand2: 'var(--colorNeutralStrokeOnBrand2)',
    colorNeutralStrokeOnBrand2Hover: 'var(--colorNeutralStrokeOnBrand2Hover)',
    colorNeutralStrokeOnBrand2Pressed: 'var(--colorNeutralStrokeOnBrand2Pressed)',
    colorNeutralStrokeOnBrand2Selected: 'var(--colorNeutralStrokeOnBrand2Selected)',
    colorBrandStroke1: 'var(--colorBrandStroke1)',
    colorBrandStroke2: 'var(--colorBrandStroke2)',
    colorBrandStroke2Hover: 'var(--colorBrandStroke2Hover)',
    colorBrandStroke2Pressed: 'var(--colorBrandStroke2Pressed)',
    colorBrandStroke2Contrast: 'var(--colorBrandStroke2Contrast)',
    colorCompoundBrandStroke: 'var(--colorCompoundBrandStroke)',
    colorCompoundBrandStrokeHover: 'var(--colorCompoundBrandStrokeHover)',
    colorCompoundBrandStrokePressed: 'var(--colorCompoundBrandStrokePressed)',
    colorNeutralStrokeDisabled: 'var(--colorNeutralStrokeDisabled)',
    colorNeutralStrokeInvertedDisabled: 'var(--colorNeutralStrokeInvertedDisabled)',
    colorTransparentStroke: 'var(--colorTransparentStroke)',
    colorTransparentStrokeInteractive: 'var(--colorTransparentStrokeInteractive)',
    colorTransparentStrokeDisabled: 'var(--colorTransparentStrokeDisabled)',
    colorNeutralStrokeAlpha: 'var(--colorNeutralStrokeAlpha)',
    colorNeutralStrokeAlpha2: 'var(--colorNeutralStrokeAlpha2)',
    colorStrokeFocus1: 'var(--colorStrokeFocus1)',
    colorStrokeFocus2: 'var(--colorStrokeFocus2)',
    colorNeutralShadowAmbient: 'var(--colorNeutralShadowAmbient)',
    colorNeutralShadowKey: 'var(--colorNeutralShadowKey)',
    colorNeutralShadowAmbientLighter: 'var(--colorNeutralShadowAmbientLighter)',
    colorNeutralShadowKeyLighter: 'var(--colorNeutralShadowKeyLighter)',
    colorNeutralShadowAmbientDarker: 'var(--colorNeutralShadowAmbientDarker)',
    colorNeutralShadowKeyDarker: 'var(--colorNeutralShadowKeyDarker)',
    colorBrandShadowAmbient: 'var(--colorBrandShadowAmbient)',
    colorBrandShadowKey: 'var(--colorBrandShadowKey)',
    // Color palette tokens
    // Color palette red tokens
    colorPaletteRedBackground1: 'var(--colorPaletteRedBackground1)',
    colorPaletteRedBackground2: 'var(--colorPaletteRedBackground2)',
    colorPaletteRedBackground3: 'var(--colorPaletteRedBackground3)',
    colorPaletteRedBorderActive: 'var(--colorPaletteRedBorderActive)',
    colorPaletteRedBorder1: 'var(--colorPaletteRedBorder1)',
    colorPaletteRedBorder2: 'var(--colorPaletteRedBorder2)',
    colorPaletteRedForeground1: 'var(--colorPaletteRedForeground1)',
    colorPaletteRedForeground2: 'var(--colorPaletteRedForeground2)',
    colorPaletteRedForeground3: 'var(--colorPaletteRedForeground3)',
    colorPaletteRedForegroundInverted: 'var(--colorPaletteRedForegroundInverted)',
    // Color palette green tokens
    colorPaletteGreenBackground1: 'var(--colorPaletteGreenBackground1)',
    colorPaletteGreenBackground2: 'var(--colorPaletteGreenBackground2)',
    colorPaletteGreenBackground3: 'var(--colorPaletteGreenBackground3)',
    colorPaletteGreenBorderActive: 'var(--colorPaletteGreenBorderActive)',
    colorPaletteGreenBorder1: 'var(--colorPaletteGreenBorder1)',
    colorPaletteGreenBorder2: 'var(--colorPaletteGreenBorder2)',
    colorPaletteGreenForeground1: 'var(--colorPaletteGreenForeground1)',
    colorPaletteGreenForeground2: 'var(--colorPaletteGreenForeground2)',
    colorPaletteGreenForeground3: 'var(--colorPaletteGreenForeground3)',
    colorPaletteGreenForegroundInverted: 'var(--colorPaletteGreenForegroundInverted)',
    // Color palette dark orange tokens
    colorPaletteDarkOrangeBackground1: 'var(--colorPaletteDarkOrangeBackground1)',
    colorPaletteDarkOrangeBackground2: 'var(--colorPaletteDarkOrangeBackground2)',
    colorPaletteDarkOrangeBackground3: 'var(--colorPaletteDarkOrangeBackground3)',
    colorPaletteDarkOrangeBorderActive: 'var(--colorPaletteDarkOrangeBorderActive)',
    colorPaletteDarkOrangeBorder1: 'var(--colorPaletteDarkOrangeBorder1)',
    colorPaletteDarkOrangeBorder2: 'var(--colorPaletteDarkOrangeBorder2)',
    colorPaletteDarkOrangeForeground1: 'var(--colorPaletteDarkOrangeForeground1)',
    colorPaletteDarkOrangeForeground2: 'var(--colorPaletteDarkOrangeForeground2)',
    colorPaletteDarkOrangeForeground3: 'var(--colorPaletteDarkOrangeForeground3)',
    // Color palette yellow tokens
    colorPaletteYellowBackground1: 'var(--colorPaletteYellowBackground1)',
    colorPaletteYellowBackground2: 'var(--colorPaletteYellowBackground2)',
    colorPaletteYellowBackground3: 'var(--colorPaletteYellowBackground3)',
    colorPaletteYellowBorderActive: 'var(--colorPaletteYellowBorderActive)',
    colorPaletteYellowBorder1: 'var(--colorPaletteYellowBorder1)',
    colorPaletteYellowBorder2: 'var(--colorPaletteYellowBorder2)',
    colorPaletteYellowForeground1: 'var(--colorPaletteYellowForeground1)',
    colorPaletteYellowForeground2: 'var(--colorPaletteYellowForeground2)',
    colorPaletteYellowForeground3: 'var(--colorPaletteYellowForeground3)',
    colorPaletteYellowForegroundInverted: 'var(--colorPaletteYellowForegroundInverted)',
    // Color palette berry tokens
    colorPaletteBerryBackground1: 'var(--colorPaletteBerryBackground1)',
    colorPaletteBerryBackground2: 'var(--colorPaletteBerryBackground2)',
    colorPaletteBerryBackground3: 'var(--colorPaletteBerryBackground3)',
    colorPaletteBerryBorderActive: 'var(--colorPaletteBerryBorderActive)',
    colorPaletteBerryBorder1: 'var(--colorPaletteBerryBorder1)',
    colorPaletteBerryBorder2: 'var(--colorPaletteBerryBorder2)',
    colorPaletteBerryForeground1: 'var(--colorPaletteBerryForeground1)',
    colorPaletteBerryForeground2: 'var(--colorPaletteBerryForeground2)',
    colorPaletteBerryForeground3: 'var(--colorPaletteBerryForeground3)',
    // Color palette marigold tokens
    colorPaletteMarigoldBackground1: 'var(--colorPaletteMarigoldBackground1)',
    colorPaletteMarigoldBackground2: 'var(--colorPaletteMarigoldBackground2)',
    colorPaletteMarigoldBackground3: 'var(--colorPaletteMarigoldBackground3)',
    colorPaletteMarigoldBorderActive: 'var(--colorPaletteMarigoldBorderActive)',
    colorPaletteMarigoldBorder1: 'var(--colorPaletteMarigoldBorder1)',
    colorPaletteMarigoldBorder2: 'var(--colorPaletteMarigoldBorder2)',
    colorPaletteMarigoldForeground1: 'var(--colorPaletteMarigoldForeground1)',
    colorPaletteMarigoldForeground2: 'var(--colorPaletteMarigoldForeground2)',
    colorPaletteMarigoldForeground3: 'var(--colorPaletteMarigoldForeground3)',
    // Color palette light green tokens
    colorPaletteLightGreenBackground1: 'var(--colorPaletteLightGreenBackground1)',
    colorPaletteLightGreenBackground2: 'var(--colorPaletteLightGreenBackground2)',
    colorPaletteLightGreenBackground3: 'var(--colorPaletteLightGreenBackground3)',
    colorPaletteLightGreenBorderActive: 'var(--colorPaletteLightGreenBorderActive)',
    colorPaletteLightGreenBorder1: 'var(--colorPaletteLightGreenBorder1)',
    colorPaletteLightGreenBorder2: 'var(--colorPaletteLightGreenBorder2)',
    colorPaletteLightGreenForeground1: 'var(--colorPaletteLightGreenForeground1)',
    colorPaletteLightGreenForeground2: 'var(--colorPaletteLightGreenForeground2)',
    colorPaletteLightGreenForeground3: 'var(--colorPaletteLightGreenForeground3)',
    // Color palette anchor tokens
    colorPaletteAnchorBackground2: 'var(--colorPaletteAnchorBackground2)',
    colorPaletteAnchorBorderActive: 'var(--colorPaletteAnchorBorderActive)',
    colorPaletteAnchorForeground2: 'var(--colorPaletteAnchorForeground2)',
    // Color palette beige tokens
    colorPaletteBeigeBackground2: 'var(--colorPaletteBeigeBackground2)',
    colorPaletteBeigeBorderActive: 'var(--colorPaletteBeigeBorderActive)',
    colorPaletteBeigeForeground2: 'var(--colorPaletteBeigeForeground2)',
    // Color palette blue tokens
    colorPaletteBlueBackground2: 'var(--colorPaletteBlueBackground2)',
    colorPaletteBlueBorderActive: 'var(--colorPaletteBlueBorderActive)',
    colorPaletteBlueForeground2: 'var(--colorPaletteBlueForeground2)',
    // Color palette brass tokens
    colorPaletteBrassBackground2: 'var(--colorPaletteBrassBackground2)',
    colorPaletteBrassBorderActive: 'var(--colorPaletteBrassBorderActive)',
    colorPaletteBrassForeground2: 'var(--colorPaletteBrassForeground2)',
    // Color palette brown tokens
    colorPaletteBrownBackground2: 'var(--colorPaletteBrownBackground2)',
    colorPaletteBrownBorderActive: 'var(--colorPaletteBrownBorderActive)',
    colorPaletteBrownForeground2: 'var(--colorPaletteBrownForeground2)',
    // Color palette cornflower tokens
    colorPaletteCornflowerBackground2: 'var(--colorPaletteCornflowerBackground2)',
    colorPaletteCornflowerBorderActive: 'var(--colorPaletteCornflowerBorderActive)',
    colorPaletteCornflowerForeground2: 'var(--colorPaletteCornflowerForeground2)',
    // Color palette cranberry tokens
    colorPaletteCranberryBackground2: 'var(--colorPaletteCranberryBackground2)',
    colorPaletteCranberryBorderActive: 'var(--colorPaletteCranberryBorderActive)',
    colorPaletteCranberryForeground2: 'var(--colorPaletteCranberryForeground2)',
    // Color palette dark green tokens
    colorPaletteDarkGreenBackground2: 'var(--colorPaletteDarkGreenBackground2)',
    colorPaletteDarkGreenBorderActive: 'var(--colorPaletteDarkGreenBorderActive)',
    colorPaletteDarkGreenForeground2: 'var(--colorPaletteDarkGreenForeground2)',
    // Color palette dark red tokens
    colorPaletteDarkRedBackground2: 'var(--colorPaletteDarkRedBackground2)',
    colorPaletteDarkRedBorderActive: 'var(--colorPaletteDarkRedBorderActive)',
    colorPaletteDarkRedForeground2: 'var(--colorPaletteDarkRedForeground2)',
    // Color palette forest tokens
    colorPaletteForestBackground2: 'var(--colorPaletteForestBackground2)',
    colorPaletteForestBorderActive: 'var(--colorPaletteForestBorderActive)',
    colorPaletteForestForeground2: 'var(--colorPaletteForestForeground2)',
    // Color palette gold tokens
    colorPaletteGoldBackground2: 'var(--colorPaletteGoldBackground2)',
    colorPaletteGoldBorderActive: 'var(--colorPaletteGoldBorderActive)',
    colorPaletteGoldForeground2: 'var(--colorPaletteGoldForeground2)',
    // Color palette grape tokens
    colorPaletteGrapeBackground2: 'var(--colorPaletteGrapeBackground2)',
    colorPaletteGrapeBorderActive: 'var(--colorPaletteGrapeBorderActive)',
    colorPaletteGrapeForeground2: 'var(--colorPaletteGrapeForeground2)',
    // Color palette lavender tokens
    colorPaletteLavenderBackground2: 'var(--colorPaletteLavenderBackground2)',
    colorPaletteLavenderBorderActive: 'var(--colorPaletteLavenderBorderActive)',
    colorPaletteLavenderForeground2: 'var(--colorPaletteLavenderForeground2)',
    // Color palette light teal tokens
    colorPaletteLightTealBackground2: 'var(--colorPaletteLightTealBackground2)',
    colorPaletteLightTealBorderActive: 'var(--colorPaletteLightTealBorderActive)',
    colorPaletteLightTealForeground2: 'var(--colorPaletteLightTealForeground2)',
    // Color palette lilac tokens
    colorPaletteLilacBackground2: 'var(--colorPaletteLilacBackground2)',
    colorPaletteLilacBorderActive: 'var(--colorPaletteLilacBorderActive)',
    colorPaletteLilacForeground2: 'var(--colorPaletteLilacForeground2)',
    // Color palette magenta tokens
    colorPaletteMagentaBackground2: 'var(--colorPaletteMagentaBackground2)',
    colorPaletteMagentaBorderActive: 'var(--colorPaletteMagentaBorderActive)',
    colorPaletteMagentaForeground2: 'var(--colorPaletteMagentaForeground2)',
    // Color palette mink tokens
    colorPaletteMinkBackground2: 'var(--colorPaletteMinkBackground2)',
    colorPaletteMinkBorderActive: 'var(--colorPaletteMinkBorderActive)',
    colorPaletteMinkForeground2: 'var(--colorPaletteMinkForeground2)',
    // Color palette navy tokens
    colorPaletteNavyBackground2: 'var(--colorPaletteNavyBackground2)',
    colorPaletteNavyBorderActive: 'var(--colorPaletteNavyBorderActive)',
    colorPaletteNavyForeground2: 'var(--colorPaletteNavyForeground2)',
    // Color palette peach tokens
    colorPalettePeachBackground2: 'var(--colorPalettePeachBackground2)',
    colorPalettePeachBorderActive: 'var(--colorPalettePeachBorderActive)',
    colorPalettePeachForeground2: 'var(--colorPalettePeachForeground2)',
    // Color palette pink tokens
    colorPalettePinkBackground2: 'var(--colorPalettePinkBackground2)',
    colorPalettePinkBorderActive: 'var(--colorPalettePinkBorderActive)',
    colorPalettePinkForeground2: 'var(--colorPalettePinkForeground2)',
    // Color palette platinum tokens
    colorPalettePlatinumBackground2: 'var(--colorPalettePlatinumBackground2)',
    colorPalettePlatinumBorderActive: 'var(--colorPalettePlatinumBorderActive)',
    colorPalettePlatinumForeground2: 'var(--colorPalettePlatinumForeground2)',
    // Color palette plum tokens
    colorPalettePlumBackground2: 'var(--colorPalettePlumBackground2)',
    colorPalettePlumBorderActive: 'var(--colorPalettePlumBorderActive)',
    colorPalettePlumForeground2: 'var(--colorPalettePlumForeground2)',
    // Color palette pumpkin tokens
    colorPalettePumpkinBackground2: 'var(--colorPalettePumpkinBackground2)',
    colorPalettePumpkinBorderActive: 'var(--colorPalettePumpkinBorderActive)',
    colorPalettePumpkinForeground2: 'var(--colorPalettePumpkinForeground2)',
    // Color palette purple tokens
    colorPalettePurpleBackground2: 'var(--colorPalettePurpleBackground2)',
    colorPalettePurpleBorderActive: 'var(--colorPalettePurpleBorderActive)',
    colorPalettePurpleForeground2: 'var(--colorPalettePurpleForeground2)',
    // Color palette royal blue tokens
    colorPaletteRoyalBlueBackground2: 'var(--colorPaletteRoyalBlueBackground2)',
    colorPaletteRoyalBlueBorderActive: 'var(--colorPaletteRoyalBlueBorderActive)',
    colorPaletteRoyalBlueForeground2: 'var(--colorPaletteRoyalBlueForeground2)',
    // Color palette seafoam tokens
    colorPaletteSeafoamBackground2: 'var(--colorPaletteSeafoamBackground2)',
    colorPaletteSeafoamBorderActive: 'var(--colorPaletteSeafoamBorderActive)',
    colorPaletteSeafoamForeground2: 'var(--colorPaletteSeafoamForeground2)',
    // Color palette steel tokens
    colorPaletteSteelBackground2: 'var(--colorPaletteSteelBackground2)',
    colorPaletteSteelBorderActive: 'var(--colorPaletteSteelBorderActive)',
    colorPaletteSteelForeground2: 'var(--colorPaletteSteelForeground2)',
    // Color palette teal tokens
    colorPaletteTealBackground2: 'var(--colorPaletteTealBackground2)',
    colorPaletteTealBorderActive: 'var(--colorPaletteTealBorderActive)',
    colorPaletteTealForeground2: 'var(--colorPaletteTealForeground2)',
    // Color status success tokens
    colorStatusSuccessBackground1: 'var(--colorStatusSuccessBackground1)',
    colorStatusSuccessBackground2: 'var(--colorStatusSuccessBackground2)',
    colorStatusSuccessBackground3: 'var(--colorStatusSuccessBackground3)',
    colorStatusSuccessForeground1: 'var(--colorStatusSuccessForeground1)',
    colorStatusSuccessForeground2: 'var(--colorStatusSuccessForeground2)',
    colorStatusSuccessForeground3: 'var(--colorStatusSuccessForeground3)',
    colorStatusSuccessForegroundInverted: 'var(--colorStatusSuccessForegroundInverted)',
    colorStatusSuccessBorderActive: 'var(--colorStatusSuccessBorderActive)',
    colorStatusSuccessBorder1: 'var(--colorStatusSuccessBorder1)',
    colorStatusSuccessBorder2: 'var(--colorStatusSuccessBorder2)',
    // Color status warning tokens
    colorStatusWarningBackground1: 'var(--colorStatusWarningBackground1)',
    colorStatusWarningBackground2: 'var(--colorStatusWarningBackground2)',
    colorStatusWarningBackground3: 'var(--colorStatusWarningBackground3)',
    colorStatusWarningForeground1: 'var(--colorStatusWarningForeground1)',
    colorStatusWarningForeground2: 'var(--colorStatusWarningForeground2)',
    colorStatusWarningForeground3: 'var(--colorStatusWarningForeground3)',
    colorStatusWarningForegroundInverted: 'var(--colorStatusWarningForegroundInverted)',
    colorStatusWarningBorderActive: 'var(--colorStatusWarningBorderActive)',
    colorStatusWarningBorder1: 'var(--colorStatusWarningBorder1)',
    colorStatusWarningBorder2: 'var(--colorStatusWarningBorder2)',
    // Color status danger tokens
    colorStatusDangerBackground1: 'var(--colorStatusDangerBackground1)',
    colorStatusDangerBackground2: 'var(--colorStatusDangerBackground2)',
    colorStatusDangerBackground3: 'var(--colorStatusDangerBackground3)',
    colorStatusDangerForeground1: 'var(--colorStatusDangerForeground1)',
    colorStatusDangerForeground2: 'var(--colorStatusDangerForeground2)',
    colorStatusDangerForeground3: 'var(--colorStatusDangerForeground3)',
    colorStatusDangerForegroundInverted: 'var(--colorStatusDangerForegroundInverted)',
    colorStatusDangerBorderActive: 'var(--colorStatusDangerBorderActive)',
    colorStatusDangerBorder1: 'var(--colorStatusDangerBorder1)',
    colorStatusDangerBorder2: 'var(--colorStatusDangerBorder2)',
    // Border radius tokens
    borderRadiusNone: 'var(--borderRadiusNone)',
    borderRadiusSmall: 'var(--borderRadiusSmall)',
    borderRadiusMedium: 'var(--borderRadiusMedium)',
    borderRadiusLarge: 'var(--borderRadiusLarge)',
    borderRadiusXLarge: 'var(--borderRadiusXLarge)',
    borderRadiusCircular: 'var(--borderRadiusCircular)',
    // Font family tokens
    fontFamilyBase: 'var(--fontFamilyBase)',
    fontFamilyMonospace: 'var(--fontFamilyMonospace)',
    fontFamilyNumeric: 'var(--fontFamilyNumeric)',
    // Font size tokens
    fontSizeBase100: 'var(--fontSizeBase100)',
    fontSizeBase200: 'var(--fontSizeBase200)',
    fontSizeBase300: 'var(--fontSizeBase300)',
    fontSizeBase400: 'var(--fontSizeBase400)',
    fontSizeBase500: 'var(--fontSizeBase500)',
    fontSizeBase600: 'var(--fontSizeBase600)',
    fontSizeHero700: 'var(--fontSizeHero700)',
    fontSizeHero800: 'var(--fontSizeHero800)',
    fontSizeHero900: 'var(--fontSizeHero900)',
    fontSizeHero1000: 'var(--fontSizeHero1000)',
    // Font weight tokens
    fontWeightRegular: 'var(--fontWeightRegular)',
    fontWeightMedium: 'var(--fontWeightMedium)',
    fontWeightSemibold: 'var(--fontWeightSemibold)',
    fontWeightBold: 'var(--fontWeightBold)',
    // Line height tokens
    lineHeightBase100: 'var(--lineHeightBase100)',
    lineHeightBase200: 'var(--lineHeightBase200)',
    lineHeightBase300: 'var(--lineHeightBase300)',
    lineHeightBase400: 'var(--lineHeightBase400)',
    lineHeightBase500: 'var(--lineHeightBase500)',
    lineHeightBase600: 'var(--lineHeightBase600)',
    lineHeightHero700: 'var(--lineHeightHero700)',
    lineHeightHero800: 'var(--lineHeightHero800)',
    lineHeightHero900: 'var(--lineHeightHero900)',
    lineHeightHero1000: 'var(--lineHeightHero1000)',
    // Shadow tokens
    shadow2: 'var(--shadow2)',
    shadow4: 'var(--shadow4)',
    shadow8: 'var(--shadow8)',
    shadow16: 'var(--shadow16)',
    shadow28: 'var(--shadow28)',
    shadow64: 'var(--shadow64)',
    // Shadow brand tokens
    shadow2Brand: 'var(--shadow2Brand)',
    shadow4Brand: 'var(--shadow4Brand)',
    shadow8Brand: 'var(--shadow8Brand)',
    shadow16Brand: 'var(--shadow16Brand)',
    shadow28Brand: 'var(--shadow28Brand)',
    shadow64Brand: 'var(--shadow64Brand)',
    // Stroke width tokens
    strokeWidthThin: 'var(--strokeWidthThin)',
    strokeWidthThick: 'var(--strokeWidthThick)',
    strokeWidthThicker: 'var(--strokeWidthThicker)',
    strokeWidthThickest: 'var(--strokeWidthThickest)',
    // Spacings
    spacingHorizontalNone: 'var(--spacingHorizontalNone)',
    spacingHorizontalXXS: 'var(--spacingHorizontalXXS)',
    spacingHorizontalXS: 'var(--spacingHorizontalXS)',
    spacingHorizontalSNudge: 'var(--spacingHorizontalSNudge)',
    spacingHorizontalS: 'var(--spacingHorizontalS)',
    spacingHorizontalMNudge: 'var(--spacingHorizontalMNudge)',
    spacingHorizontalM: 'var(--spacingHorizontalM)',
    spacingHorizontalL: 'var(--spacingHorizontalL)',
    spacingHorizontalXL: 'var(--spacingHorizontalXL)',
    spacingHorizontalXXL: 'var(--spacingHorizontalXXL)',
    spacingHorizontalXXXL: 'var(--spacingHorizontalXXXL)',
    spacingVerticalNone: 'var(--spacingVerticalNone)',
    spacingVerticalXXS: 'var(--spacingVerticalXXS)',
    spacingVerticalXS: 'var(--spacingVerticalXS)',
    spacingVerticalSNudge: 'var(--spacingVerticalSNudge)',
    spacingVerticalS: 'var(--spacingVerticalS)',
    spacingVerticalMNudge: 'var(--spacingVerticalMNudge)',
    spacingVerticalM: 'var(--spacingVerticalM)',
    spacingVerticalL: 'var(--spacingVerticalL)',
    spacingVerticalXL: 'var(--spacingVerticalXL)',
    spacingVerticalXXL: 'var(--spacingVerticalXXL)',
    spacingVerticalXXXL: 'var(--spacingVerticalXXXL)',
    // Durations
    durationUltraFast: 'var(--durationUltraFast)',
    durationFaster: 'var(--durationFaster)',
    durationFast: 'var(--durationFast)',
    durationNormal: 'var(--durationNormal)',
    durationGentle: 'var(--durationGentle)',
    durationSlow: 'var(--durationSlow)',
    durationSlower: 'var(--durationSlower)',
    durationUltraSlow: 'var(--durationUltraSlow)',
    // Curves
    curveAccelerateMax: 'var(--curveAccelerateMax)',
    curveAccelerateMid: 'var(--curveAccelerateMid)',
    curveAccelerateMin: 'var(--curveAccelerateMin)',
    curveDecelerateMax: 'var(--curveDecelerateMax)',
    curveDecelerateMid: 'var(--curveDecelerateMid)',
    curveDecelerateMin: 'var(--curveDecelerateMin)',
    curveEasyEaseMax: 'var(--curveEasyEaseMax)',
    curveEasyEase: 'var(--curveEasyEase)',
    curveLinear: 'var(--curveLinear)'
};
