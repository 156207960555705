import * as React from 'react';
import { useFluent_unstable as useFluent } from '@fluentui/react-shared-contexts';
import { createTabster, disposeTabster } from 'tabster';
import { useIsomorphicLayoutEffect, getParent } from '@fluentui/react-utilities';
/**
 * Tries to get a tabster instance on the current window or creates a new one
 * Since Tabster is single instance only, feel free to call this hook to ensure Tabster exists if necessary
 *
 * @internal
 * @returns Tabster core instance
 */ export const useTabster = ()=>{
    const { targetDocument } = useFluent();
    const defaultView = (targetDocument === null || targetDocument === void 0 ? void 0 : targetDocument.defaultView) || undefined;
    const tabster = React.useMemo(()=>{
        if (!defaultView) {
            return null;
        }
        return createTabster(defaultView, {
            autoRoot: {},
            controlTab: false,
            getParent,
            checkUncontrolledTrappingFocus: (element)=>{
                var _element_firstElementChild;
                return !!((_element_firstElementChild = element.firstElementChild) === null || _element_firstElementChild === void 0 ? void 0 : _element_firstElementChild.hasAttribute('data-is-focus-trap-zone-bumper'));
            }
        });
    }, [
        defaultView
    ]);
    useIsomorphicLayoutEffect(()=>{
        return ()=>{
            if (tabster) {
                disposeTabster(tabster);
            }
        };
    }, [
        tabster
    ]);
    return tabster;
};
