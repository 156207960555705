/* Names of colors used in shared color palette alias tokens for status. */ export const statusSharedColorNames = [
    'red',
    'green',
    'darkOrange',
    'yellow',
    'berry',
    'lightGreen',
    'marigold'
];
/* Names of colors used in shared color palette alias tokens for persona. */ export const personaSharedColorNames = [
    'darkRed',
    'cranberry',
    'pumpkin',
    'peach',
    'gold',
    'brass',
    'brown',
    'forest',
    'seafoam',
    'darkGreen',
    'lightTeal',
    'teal',
    'steel',
    'blue',
    'royalBlue',
    'cornflower',
    'navy',
    'lavender',
    'purple',
    'grape',
    'lilac',
    'pink',
    'magenta',
    'plum',
    'beige',
    'mink',
    'platinum',
    'anchor'
];
/* List of global colors which semantic alias status tokens map to */ export const mappedStatusColorNames = [
    'cranberry',
    'green',
    'orange'
];
/* Names of colors not used in alias tokens but produced by token pipeline as global color tokens. */ export const unusedSharedColorNames = [
    'burgundy',
    'bronze',
    'orange',
    'darkBrown',
    'lime',
    'darkTeal',
    'cyan',
    'lightBlue',
    'darkBlue',
    'darkPurple',
    'orchid',
    'hotPink',
    'silver',
    'charcoal'
];
