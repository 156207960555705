/* !!! DO NOT EDIT !!! */ /* This file has been generated by the token pipeline */ import { black, blackAlpha, grey, white, whiteAlpha } from '../global/colors';
export const generateColorTokens = (brand)=>({
        colorNeutralForeground1: grey[14],
        colorNeutralForeground1Hover: grey[14],
        colorNeutralForeground1Pressed: grey[14],
        colorNeutralForeground1Selected: grey[14],
        colorNeutralForeground2: grey[26],
        colorNeutralForeground2Hover: grey[14],
        colorNeutralForeground2Pressed: grey[14],
        colorNeutralForeground2Selected: grey[14],
        colorNeutralForeground2BrandHover: brand[80],
        colorNeutralForeground2BrandPressed: brand[70],
        colorNeutralForeground2BrandSelected: brand[80],
        colorNeutralForeground3: grey[38],
        colorNeutralForeground3Hover: grey[26],
        colorNeutralForeground3Pressed: grey[26],
        colorNeutralForeground3Selected: grey[26],
        colorNeutralForeground3BrandHover: brand[80],
        colorNeutralForeground3BrandPressed: brand[70],
        colorNeutralForeground3BrandSelected: brand[80],
        colorNeutralForeground4: grey[44],
        colorNeutralForegroundDisabled: grey[74],
        colorNeutralForegroundInvertedDisabled: whiteAlpha[40],
        colorBrandForegroundLink: brand[70],
        colorBrandForegroundLinkHover: brand[60],
        colorBrandForegroundLinkPressed: brand[40],
        colorBrandForegroundLinkSelected: brand[70],
        colorNeutralForeground2Link: grey[26],
        colorNeutralForeground2LinkHover: grey[14],
        colorNeutralForeground2LinkPressed: grey[14],
        colorNeutralForeground2LinkSelected: grey[14],
        colorCompoundBrandForeground1: brand[80],
        colorCompoundBrandForeground1Hover: brand[70],
        colorCompoundBrandForeground1Pressed: brand[60],
        colorBrandForeground1: brand[80],
        colorBrandForeground2: brand[70],
        colorBrandForeground2Hover: brand[60],
        colorBrandForeground2Pressed: brand[30],
        colorNeutralForeground1Static: grey[14],
        colorNeutralForegroundStaticInverted: white,
        colorNeutralForegroundInverted: white,
        colorNeutralForegroundInvertedHover: white,
        colorNeutralForegroundInvertedPressed: white,
        colorNeutralForegroundInvertedSelected: white,
        colorNeutralForegroundInverted2: white,
        colorNeutralForegroundOnBrand: white,
        colorNeutralForegroundInvertedLink: white,
        colorNeutralForegroundInvertedLinkHover: white,
        colorNeutralForegroundInvertedLinkPressed: white,
        colorNeutralForegroundInvertedLinkSelected: white,
        colorBrandForegroundInverted: brand[100],
        colorBrandForegroundInvertedHover: brand[110],
        colorBrandForegroundInvertedPressed: brand[100],
        colorBrandForegroundOnLight: brand[80],
        colorBrandForegroundOnLightHover: brand[70],
        colorBrandForegroundOnLightPressed: brand[50],
        colorBrandForegroundOnLightSelected: brand[60],
        colorNeutralBackground1: white,
        colorNeutralBackground1Hover: grey[96],
        colorNeutralBackground1Pressed: grey[88],
        colorNeutralBackground1Selected: grey[92],
        colorNeutralBackground2: grey[98],
        colorNeutralBackground2Hover: grey[94],
        colorNeutralBackground2Pressed: grey[86],
        colorNeutralBackground2Selected: grey[90],
        colorNeutralBackground3: grey[96],
        colorNeutralBackground3Hover: grey[92],
        colorNeutralBackground3Pressed: grey[84],
        colorNeutralBackground3Selected: grey[88],
        colorNeutralBackground4: grey[94],
        colorNeutralBackground4Hover: grey[98],
        colorNeutralBackground4Pressed: grey[96],
        colorNeutralBackground4Selected: white,
        colorNeutralBackground5: grey[92],
        colorNeutralBackground5Hover: grey[96],
        colorNeutralBackground5Pressed: grey[94],
        colorNeutralBackground5Selected: grey[98],
        colorNeutralBackground6: grey[90],
        colorNeutralBackgroundInverted: grey[16],
        colorNeutralBackgroundStatic: grey[20],
        colorNeutralBackgroundAlpha: whiteAlpha[50],
        colorNeutralBackgroundAlpha2: whiteAlpha[80],
        colorSubtleBackground: 'transparent',
        colorSubtleBackgroundHover: grey[96],
        colorSubtleBackgroundPressed: grey[88],
        colorSubtleBackgroundSelected: grey[92],
        colorSubtleBackgroundLightAlphaHover: whiteAlpha[70],
        colorSubtleBackgroundLightAlphaPressed: whiteAlpha[50],
        colorSubtleBackgroundLightAlphaSelected: 'transparent',
        colorSubtleBackgroundInverted: 'transparent',
        colorSubtleBackgroundInvertedHover: blackAlpha[10],
        colorSubtleBackgroundInvertedPressed: blackAlpha[30],
        colorSubtleBackgroundInvertedSelected: blackAlpha[20],
        colorTransparentBackground: 'transparent',
        colorTransparentBackgroundHover: 'transparent',
        colorTransparentBackgroundPressed: 'transparent',
        colorTransparentBackgroundSelected: 'transparent',
        colorNeutralBackgroundDisabled: grey[94],
        colorNeutralBackgroundInvertedDisabled: whiteAlpha[10],
        colorNeutralStencil1: grey[90],
        colorNeutralStencil2: grey[98],
        colorNeutralStencil1Alpha: blackAlpha[10],
        colorNeutralStencil2Alpha: blackAlpha[5],
        colorBackgroundOverlay: blackAlpha[40],
        colorScrollbarOverlay: blackAlpha[50],
        colorBrandBackground: brand[80],
        colorBrandBackgroundHover: brand[70],
        colorBrandBackgroundPressed: brand[40],
        colorBrandBackgroundSelected: brand[60],
        colorCompoundBrandBackground: brand[80],
        colorCompoundBrandBackgroundHover: brand[70],
        colorCompoundBrandBackgroundPressed: brand[60],
        colorBrandBackgroundStatic: brand[80],
        colorBrandBackground2: brand[160],
        colorBrandBackground2Hover: brand[150],
        colorBrandBackground2Pressed: brand[130],
        colorBrandBackgroundInverted: white,
        colorBrandBackgroundInvertedHover: brand[160],
        colorBrandBackgroundInvertedPressed: brand[140],
        colorBrandBackgroundInvertedSelected: brand[150],
        colorNeutralStrokeAccessible: grey[38],
        colorNeutralStrokeAccessibleHover: grey[34],
        colorNeutralStrokeAccessiblePressed: grey[30],
        colorNeutralStrokeAccessibleSelected: brand[80],
        colorNeutralStroke1: grey[82],
        colorNeutralStroke1Hover: grey[78],
        colorNeutralStroke1Pressed: grey[70],
        colorNeutralStroke1Selected: grey[74],
        colorNeutralStroke2: grey[88],
        colorNeutralStroke3: grey[94],
        colorNeutralStrokeSubtle: grey[88],
        colorNeutralStrokeOnBrand: white,
        colorNeutralStrokeOnBrand2: white,
        colorNeutralStrokeOnBrand2Hover: white,
        colorNeutralStrokeOnBrand2Pressed: white,
        colorNeutralStrokeOnBrand2Selected: white,
        colorBrandStroke1: brand[80],
        colorBrandStroke2: brand[140],
        colorBrandStroke2Hover: brand[120],
        colorBrandStroke2Pressed: brand[80],
        colorBrandStroke2Contrast: brand[140],
        colorCompoundBrandStroke: brand[80],
        colorCompoundBrandStrokeHover: brand[70],
        colorCompoundBrandStrokePressed: brand[60],
        colorNeutralStrokeDisabled: grey[88],
        colorNeutralStrokeInvertedDisabled: whiteAlpha[40],
        colorTransparentStroke: 'transparent',
        colorTransparentStrokeInteractive: 'transparent',
        colorTransparentStrokeDisabled: 'transparent',
        colorNeutralStrokeAlpha: blackAlpha[5],
        colorNeutralStrokeAlpha2: whiteAlpha[20],
        colorStrokeFocus1: white,
        colorStrokeFocus2: black,
        colorNeutralShadowAmbient: 'rgba(0,0,0,0.12)',
        colorNeutralShadowKey: 'rgba(0,0,0,0.14)',
        colorNeutralShadowAmbientLighter: 'rgba(0,0,0,0.06)',
        colorNeutralShadowKeyLighter: 'rgba(0,0,0,0.07)',
        colorNeutralShadowAmbientDarker: 'rgba(0,0,0,0.20)',
        colorNeutralShadowKeyDarker: 'rgba(0,0,0,0.24)',
        colorBrandShadowAmbient: 'rgba(0,0,0,0.30)',
        colorBrandShadowKey: 'rgba(0,0,0,0.25)'
    });
